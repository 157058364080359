import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = [ 'hidden' ];

  hide({ params: { id }}) {
    document.getElementById(id).classList.add(this.class)
  }

  show({ params: { id }}) {
    document.getElementById(id).classList.remove(this.class)
  }

  toggle({ params: { id }}) {
    document.getElementById(id).classList.toggle(this.class);
  }

  get class() {
    return this.hasHiddenClass ? this.hiddenClass : "hidden"
  }
}
