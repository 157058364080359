import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["textArea", "buttons", "submitButton", "closeButton"]

    expand() {
        this.textAreaTarget.style.height = "200px";
        this.buttonsTarget.classList.remove("hidden");
        this.submitButtonTarget.classList.remove("hidden");
        this.closeButtonTarget.classList.remove("hidden");
    }

    collapse() {
        this.resetForm();
    }

    submitSuccess() {
        this.resetForm();
    }

    resetForm() {
        this.textAreaTarget.style.height = "50px";
        this.textAreaTarget.value = "";
        this.buttonsTarget.classList.add("hidden");
        this.submitButtonTarget.classList.add("hidden");
        this.closeButtonTarget.classList.add("hidden");
    }
}
