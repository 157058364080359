import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "row" ];
  static values = { array: Array };

  apply({target: { checked }, params: { by, value }}) {
    if (checked) {
      this.arrayValue = [...this.arrayValue, `${by}:${value}`]
      if (this.arrayValue.length === 1) {
        this.rowTargets.filter(row => row.dataset[by] !== value.toString()).forEach(t => t.classList.add('hidden'))
      } else {
        this.rowTargets.filter(row => row.dataset[by] === value.toString()).forEach(t => t.classList.remove('hidden'))
      }
    } else {
      this.arrayValue = this.arrayValue.filter(val => val !== `${by}:${value}`)
      if (this.arrayValue.length > 0) {
        this.rowTargets.filter(row => row.dataset[by] === value.toString()).forEach(target => target.classList.add('hidden'))
      } else {
        this.rowTargets.forEach(t => t.classList.remove('hidden'))
      }
    }
  }
}
