

export default {
  _callback: null,

  _read() {
    return JSON.parse(localStorage.getItem("cart")) || [];
  },

  _store(products) {
    localStorage.setItem("cart", JSON.stringify(products));
  },

  _clear() {
    localStorage.removeItem("cart");
  },

  add(product) {
    let products = this._read();
    if (!this.hasProduct(product.id, product.type)) {
      products.push(product);
      this._store(products);
    }
  },

  remove(id, type) {
    let products = this._read();
    let index = products.findIndex(p => { return p.id === id && p.type === type });
    if (index !== -1) {
      products.splice(index, 1);
      this._store(products);
    }
  },

  hasProduct(id, type) {
    let products = this._read();
    let index = products.findIndex(p => { return p.id === id && p.type === type });
    return (index !== -1);
  },

  reset() {
    this._clear();
  },

  get products() {
    return this._read();
  },

  set callback(value) {
    this._callback = value;
  }

};
