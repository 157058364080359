import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['anchor'];
  static classes = ['highlight'];

  anchorTargetConnected(element) {
    this.scrollTo(element);
  }

  scrollTo(element) {
    const anchor = window.location.hash?.replace(/^#/, '');
    if (anchor && anchor === element.id) {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
      element.classList.add(this.highlightClass);
      element.focus()
    }
  }
}
