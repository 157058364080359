const path = ";path=/";

export function setCookie(name, value, urlDomain) {
  let expires = "";

  let d = new Date();
  d.setTime(d.getTime() + 1000 * 3600 * 24 * 365);
  expires = ";expires=" + d.toUTCString();

  const domain = ";domain=" + (window.location.href.includes(urlDomain) ? "." + urlDomain : ".localhost.test");

  document.cookie = `${name}=${value}${domain}${expires}${path}`;
}

export function getCookie(name) {
  if (!decodeURIComponent(document.cookie)) return;

  let pair = document.cookie.split("; ").find(s => s.startsWith(`${name}=`));
  return pair && pair.substr(name.length + 1);
}
