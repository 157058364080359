import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  allow() {
    this.setConsent("allow");
    window.location.reload();
  }

  deny() {
    this.setConsent("deny");
    window.location.reload();
  }

  setConsent(value) {
    const domain = window.location.hostname;
    const age = 60 * 60 * 24 * 365; // 1 year
    document.cookie = `cookieconsent_status=${value};path=/;domain=${domain};max-age=${age}`;
  }
}
