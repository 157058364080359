import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "body" ]

    edit(event) {
        event.preventDefault()
        this.element.classList.add('editing')
        this.bodyTarget.focus()
    }

    cancel(event) {
        event.preventDefault()
        this.element.classList.remove('editing')
    }
}
