import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  async copyText({ params: { text } }) {

    const textToCopy = this.element.dataset.copyText ?? text;
    if ("clipboard" in navigator) {
      try {
        await navigator.clipboard.writeText(textToCopy)
        return true
      } catch {
        // Android WebView does not support the clipboard API fully and will throw an exception
      }
    }
    const node = this.createNode(textToCopy)
    document.body.append(node)
    const result = this.copyNode(node)
    node.remove()
    return result
  }

  createNode(text) {
    const node = document.createElement("pre")
    node.style = "width: 1px; height: 1px; position: fixed; top: 50%"
    node.textContent = text
    return node
  }

  copyNode(node) {
    const selection = document.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    selection.removeAllRanges()
    selection.addRange(range)
    return document.execCommand("copy")
  }
}
