import { Controller } from "@hotwired/stimulus";
import { load } from "recaptcha-v3";

export default class extends Controller {
  static targets = ['token'];
  static values = { action: String, key: String }

  check(event) {
    event.preventDefault();
    const button = event.currentTarget;
    button.disabled = true;
    load(this.keyValue, { autoHideBadge: true }).then(recaptcha => {
      recaptcha.execute(this.actionValue).then(token => {
        this.tokenTarget.value = token;
        this.element.requestSubmit();
      })
      .catch(error => {
        console.log(error);
        button.disabled = false;
      });
    })
    .catch(error => {
      console.log(error);
      button.disabled = false;
    });
  }
}
