import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  render({ params: { order, email, country, date } }) {
    window.gapi.load("surveyoptin", function () {
      window.gapi.surveyoptin.render(
        {
          merchant_id: 621680571,
          order_id: order,
          email: email,
          delivery_country: country,
          estimated_delivery_date: date
        });
    });
  }

  renderBadge() {
    let ratingBadgeContainer = document.createElement("div");
    document.body.appendChild(ratingBadgeContainer);
    window.gapi.load("ratingbadge", function () {
      window.gapi.ratingbadge.render(
        ratingBadgeContainer, {
          merchant_id: 621680571
        });
    });
  }
}
