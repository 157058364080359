import { Controller } from '@hotwired/stimulus';
import Cart from '../shared/cart';
import { getCookie } from '../shared/cookies';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = ['amount', 'button', 'cart'];
  static values = { amount: Number, currency: String, path: String };

  HIGHLIGHT_ADD_CLASS = 'animate-flash-green';
  HIGHLIGHT_REMOVED_CLASS = 'animate-flash-red';

  connect() {
    this.amountValue = this.total;
  }

  cartTargetConnected() {
    this.createOrder();
  }

  async createOrder(event) {
    await post(this.pathValue, {
      body: { line_items: Cart.products },
      responseKind: 'turbo-stream'
    })
  }

  amountValueChanged() {
    this.amountTargets.forEach(t => {
      t.innerText = new Intl.NumberFormat(getCookie('lang'), {
        style: 'currency',
        currency: this.currencyValue
      }).format(this.total * 0.01);
    })
  }

  buttonTargetConnected(el) {
    const id = Number(el.dataset.cartIdParam);
    const type = el.dataset.cartTypeParam;
    el.checked = Cart.hasProduct(id, type)
  }

  addOrRemove({ params: { id, type, price }, currentTarget }) {
    if (Cart.hasProduct(id, type)) {
      Cart.remove(id, type);
      currentTarget.checked = false
      this.highlight(this.HIGHLIGHT_REMOVED_CLASS)
    } else {
      Cart.add({ id, type, price, currency: this.currencyValue });
      currentTarget.checked = true
      this.highlight(this.HIGHLIGHT_ADD_CLASS)
    }
    this.amountValue = this.total;
  }

  remove(event) {
    event.preventDefault();
    Cart.remove(event.params.id, event.params.type);
    this.amountValue = this.total;
    this.createOrder();
  }

  clear() {

    Cart.reset();
  }

  highlight(animationClass) {
    const cartLink = document.getElementById('cartLink');

    if (cartLink) {
      cartLink.classList.add(animationClass)
      setTimeout(() => cartLink.classList.remove(animationClass), 500)
    }
  }

  get total() {
    return Cart.products.reduce((acc, product) => acc + product.price, 0);
  }
}
