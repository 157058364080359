import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['chart'];
  static values = { data: Object, type: String };

  chartTargetConnected(element) {
    new Chart(element, {
      type: this.typeValue,
      data: this.dataValue
    });
  }
}
