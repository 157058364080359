import { Controller } from "@hotwired/stimulus";
import throttle from "lodash-es/throttle";

export default class extends Controller {
  static targets = [ 'form', 'order', 'page', 'titleOrder', 'usernameOrder', 'emailOrder', 'createdAtOrder', 'updatedAtOrder' ];
  static values = { order: Object };
  static classes = [ 'active' ];

  initialize() {
    this.submit = throttle(this.submit, 1000);
  }

  setOrder(event) {
    if (this.hasOrderTarget) {
      const dataset = event.currentTarget.dataset;
      const target = dataset.formTarget;
      let column = dataset.orderColumn;
      const direction = dataset.orderDirection;

      this[`${target}Targets`].forEach(t => t.classList.remove(this.activeClass));

      if (this.orderValue[column] === direction) {
        const {[column]: _, ...newValue} = this.orderValue;
        this.orderValue = newValue;
      } else {
        this.orderValue = {...this.orderValue, [column]: direction};
        event.currentTarget.classList.add(this.activeClass);
      }

      this.orderTarget.value = Object.entries(this.orderValue).map(pair => pair.join(' ')).join(', ');
      this.submit();
    }
  }

  setPage(event) {
    if (this.hasPageTarget) {
      this.pageTarget.value = event.currentTarget.dataset.page;
      this.formTarget.requestSubmit();
    }
  }

  submit() {
    if (this.hasPageTarget) {
      this.pageTarget.value = '';
    }
    this.formTarget.requestSubmit();
  }
}
