import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'progress', 'thumb'];

  connect() {
    this.move();
  }

  move() {
    this.progressTarget.style.setProperty('--tw-translate-x', `-${100 - (this.inputTarget.value / this.inputTarget.max) * 100}%`)
  }
}
