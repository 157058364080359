import { Controller } from '@hotwired/stimulus';
import { loadStripe } from '@stripe/stripe-js/pure';


export default class extends Controller {
  static targets = [ 'cardElement', 'cardErrors', 'form', 'paymentMethod' ];
  static values = { key: String };

  async connect() {
    this.stripe = await loadStripe(this.keyValue);
    const elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', {
      style: style
    });

    this.card.mount(this.cardElementTarget);
  }

  addCard(event) {
    event.preventDefault();
    event.currentTarget.disabled = true;
    const form = this.formTarget;
    const input = this.paymentMethodTarget;
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card
      })
      .then(function(result) {
        if (result.error) {
          console.log(result.error);
        } else {
          input.value = result.paymentMethod.id;
          console.log(input.value);
          form.requestSubmit();
        }
      });
  }
}
