import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['quantity', 'priceOutput', 'quantityOutput'];
  static values = { base: Number };

  quantityTargetConnected() {
    this.calculatePrice();
  }

  calculatePrice() {
    const price = Math.round(Number(this.quantityTarget.value) * this.baseValue * 100) / 100;
    this.priceOutputTargets.forEach(t => t.innerText = price)
    this.quantityOutputTargets.forEach(t => t.innerText = this.quantityTarget.value)
  }
}
