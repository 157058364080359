import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['audio', 'button', 'duration', 'playIcon', 'pauseIcon', 'player', 'playerBtn', 'playerPlayIcon', 'playerPauseIcon', 'playerLink', 'playerImg', 'playerTitle', 'playerType', 'seeker', 'timer'];
  static values = { current: Number };
  static classes = [];

  get audio() {
    return this.audioTarget
  }

  get duration() {
    return this.durationTarget
  }

  get playlist() {
    return JSON.parse(localStorage.getItem('playlist'))
  }

  get seeker() {
    return this.seekerTarget
  }

  get timer() {
    return this.timerTarget
  }

/** Implementation of the functionality of the audio player */


  calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  whilePlaying = () => {
    this.seeker.value = this.audio.currentTime;
    this.seeker.dispatchEvent(new Event('input'));
    this.timer.textContent = this.calculateTime(this.seeker.value);
    this.raf = requestAnimationFrame(this.whilePlaying);
  }

  initialize() {
    if (!this.audio.src) {
      this.raf = null;
      this.audio.src = 'data:audio/mpeg;base64,//OExAAAAAAAAAAAAEluZm8AAAAHAAAABAAAASAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPz8/Pz8/Pz8/Pz8/Pz8/Pz8/Pz8/Pz8/P39/f39/f39/f39/f39/f39/f39/f39/f3+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/AAAAAAAAAAAAAAAAAAAAAAAAAAAAJAa/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//MUxAAAAANIAAAAAExBTUUzLjk2LjFV//MUxAsAAANIAAAAAFVVVVVVVVVVVVVV//MUxBYAAANIAAAAAFVVVVVVVVVVVVVV//MUxCEAAANIAAAAAFVVVVVVVVVVVVVV';
      document.body.addEventListener('click', this.unlockAudio)
      document.body.addEventListener('touchstart', this.unlockAudio)
    } else {
      requestAnimationFrame(this.whilePlaying)
    }

    if (!this.playlist) {
      localStorage.setItem('playlist', JSON.stringify([]))
    }
  }

  playerSetup() {
    this.duration.textContent = this.calculateTime(this.audio.duration);
    this.seeker.max = Math.floor(this.audio.duration);
  }

  playerPause() {
    cancelAnimationFrame(this.raf);
    this.playerPlayIconTarget.classList.remove('hidden');
    this.playerPauseIconTarget.classList.add('hidden');
  }

  playerResume() {
    requestAnimationFrame(this.whilePlaying)
    this.playerPlayIconTarget.classList.add('hidden');
    this.playerPauseIconTarget.classList.remove('hidden');
  }

  playerStop() {
    this.pauseIconTargets.forEach(icon => icon.classList.add('hidden'));
    this.playIconTargets.forEach(icon => icon.classList.remove('hidden'));
    this.playerPause();
  }

  seekerInput() {
    this.timer.textContent = this.calculateTime(this.seeker.value);
    if(!this.audio.paused) {
      cancelAnimationFrame(this.raf);
    }
  }

  seekerChange() {
    this.audio.currentTime = this.seeker.value;
      if(!this.audio.paused) {
        requestAnimationFrame(this.whilePlaying);
      }
  }

  unlockAudio = () => {
    document.body.removeEventListener('click', this.unlockAudio);
    document.body.removeEventListener('touchstart', this.unlockAudio);
    this.audio
      .play()
      .then(() => {})
      .catch(() => {})
  };

  play({ params: { url, img, link, title, type }, currentTarget }) {
    const playIcon = this.playIconTargets.find(icon => icon.parentNode === currentTarget);
    const pauseIcon = this.pauseIconTargets.find(icon => icon.parentNode === currentTarget);

    if (this.audio.src === url) {
      const isPlaying = !this.audio.paused;

      if (isPlaying) {
        currentTarget.classList.remove('opacity-100');
        this.audio.pause();
      }
      else {
        currentTarget.classList.add('opacity-100');
        this.audio.play();
      }

      if (playIcon) {
        isPlaying ? playIcon.classList.remove('hidden') : playIcon.classList.add('hidden');
      }

      if (pauseIcon) {
        isPlaying ? pauseIcon.classList.add('hidden') : pauseIcon.classList.remove('hidden');
      }
    }
    else {
      this.playerTarget.classList.remove('hidden');
      this.pauseIconTargets.forEach(icon => icon.classList.add('hidden'));
      this.playIconTargets.forEach(icon => icon.classList.remove('hidden'));
      this.buttonTargets.forEach(button => button.classList.remove('opacity-100'));
      this.audio.src = url;
      this.updatePlayerInfo(url, img, link, title, type);
      currentTarget.classList.add('opacity-100');
      playIcon.classList.add('hidden');
      pauseIcon.classList.remove('hidden');
      this.addToPlaylist({url, img, link, title, type})

      this.audio.play();
    }
  }

  updateVolume({ currentTarget }) {
    this.audio.volume = currentTarget.value;
  }

  updatePlayerInfo(url, img, link, title, type) {
    this.playerBtnTarget.dataset.audioUrlParam = url;
    this.playerImgTarget.src = img;
    this.playerLinkTarget.href = link;
    this.playerTitleTarget.innerText = title;
    this.playerTypeTarget.innerText = type;
  }

  prev() {
    let track;
    if (this.currentValue === 9) {
      track = this.playlist[9];
    } else {
      track = this.playlist[this.currentValue + 1];
      this.currentValue += 1
    }
    this.audio.src = track.url;
    this.updatePlayerInfo(track.url, track.img, track.link, track.title, track.type);
    this.audio.play();
  }

  next() {
    let track;
    if (this.currentValue === 0) {
      track = this.playlist[0];
    } else {
      track = this.playlist[this.currentValue - 1];
      this.currentValue -= 1
    }
    this.audio.src = track.url;
    this.updatePlayerInfo(track.url, track.img, track.link, track.title, track.type);
    this.audio.play();
  }

  hide() {
    this.playerTarget.classList.add('hidden');
  }

  addToPlaylist(track) {
    let playlist = this.playlist;
    playlist.unshift(track);
    if (playlist.length > 10) {
      playlist.length = 10;
    }
    localStorage.setItem('playlist', JSON.stringify(playlist));
  }
}
